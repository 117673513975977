import "./src/styles/index.css";
import React from "react";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
export const wrapRootElement = ({ element }) => {
  return (
      <div>


      {element}
      </div>
  );
};
